
const PRODUCTION_NAME_DEV = 'rubies5';
const PRODUCTION_NAME_DISPLAY = 'Rubies5';
const WIN1_TG_URL = 'https://t.me/+7nWPgCd7f-lhNDZk';
const WIN2_TG_URL = 'https://t.me/+bkQBIyuduCE5Njg0';
const WIN3_TG_URL = 'https://t.me/+-rUsI8Tb1P9kMDk0';
const WIN5_TG_URL = 'https://t.me/+VMdhavW6rV01ZTc0';
const MAIN_URL = 'https://www.rubies5.com';
const ENV = "production";

 export {
PRODUCTION_NAME_DEV,
PRODUCTION_NAME_DISPLAY,
WIN1_TG_URL,
WIN2_TG_URL,
WIN3_TG_URL,
WIN5_TG_URL,
MAIN_URL,
ENV,
}